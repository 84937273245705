export const handleColors = (config) => {
  const props = ["primary", "accent", "dark", "gray", "light", "primary-light"];
  props.forEach((key) => {
    const color = config[key];
    document.documentElement.style.setProperty(`--${key}`, color);
  });
};

export const handleMetaTags = (config) => {
  const {
    favicon,
    app_css,
    tagline,
    S3_ENDPOINT,
    business_name,
    social_media_image,
    head_tracking_codes,
    body_tracking_codes,
  } = config;

  var link = document.querySelector("link[rel~='icon']");
  if (link) {
    link.href = `${S3_ENDPOINT}/${favicon}`;
  }

  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.origin);

  document.title = `${business_name}`;
  document
    .querySelector('meta[itemprop="name"]')
    .setAttribute("content", business_name);
  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", business_name);
  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", business_name);

  if (tagline) {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", tagline);
    document
      .querySelector('meta[itemprop="description"]')
      .setAttribute("content", tagline);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", tagline);
    document
      .querySelector('meta[name="twitter:description"]')
      .setAttribute("content", tagline);
  }

  if (social_media_image) {
    document
      .querySelector('meta[itemprop="image"]')
      .setAttribute("content", social_media_image);
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", social_media_image);
    document
      .querySelector('meta[name="twitter:image"]')
      .setAttribute("content", social_media_image);
  }

  if (head_tracking_codes) {
    const script = document.querySelector("script#head_tacking_codes");
    script.innerHTML = `
        try {
        
          ${head_tracking_codes}
        } catch (error) {
          
        }
      `;
  }

  if (body_tracking_codes) {
    const script = document.querySelector("script#body_tacking_codes");
    script.innerHTML = `
        try {
        
          ${body_tracking_codes}
        } catch (error) {
          
        }
    `;
  }

  if (app_css) {
    const styles = document.querySelector("style#appconfig_styles");
    styles.innerHTML = app_css;
  }
};
